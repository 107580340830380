import * as React from 'react';

import { Button, Checkbox, Col, Input, InputNumber, Modal, Row, Select, Table } from 'antd';

import styles from '../resources/Settings.module.less';
import pageStyles from '../resources/Page.module.less';
import { Command, Group, Point, Resource } from '../decl';
import * as Const from '../utils/Const';
import PointsControlTree, { NodeTypesEnum } from './PointsControlTree';
import EditNumberForm from './Helper/EditNumberForm';
import { getModalZIndex } from '../utils/Common';import { getAllSelectedPoints } from '../utils/TreeUtils';

const { Option } = Select;

type Props = {
    groups: Group[],
    groupPoints: {[groupId: string] : Point[]},
    getPoints: any,
    getPointsParentGroups: any,
    setIsLoading: any,
    onOk: (abonentsDaySendMaxCount: {[pointId: string]: number}) => void,
    onHide: () => void,
    visible: boolean,
    zIndex: number,
    abonentsDaySendMaxCount: {[pointId: string]: number},
    smallWindow: boolean,
}

type State = {
    abonentsDaySendMaxCount: {[pointId: string]: number},
    showEditNumForm: any,
    editNum: number | null,
    selectedPoint: Point | undefined,
    modalZIndex: number
}

class ContactPointDaySendMaxCount extends React.Component<Props, State> {
    constructor(props: Props, state: State) {
        super(props);

        this.state = {
            abonentsDaySendMaxCount: {},
            showEditNumForm: null,
            editNum: null,
            selectedPoint: undefined,
            modalZIndex: 0
        }
    }
    
    componentDidUpdate(prevProps: Props) {
        if (prevProps.visible === false && this.props.visible === true)
        {
            this.setState({abonentsDaySendMaxCount: {...this.props.abonentsDaySendMaxCount}});
        }
    }

    additionalNodeTitle = (ob: Point | Group | null): string => {
        let res = '';
        const obj = (ob as Point)
        if (obj?.number != undefined) {
            if (this.state?.abonentsDaySendMaxCount[obj.id] != undefined) {
                let c = this.state.abonentsDaySendMaxCount[obj.id];
                res = ' [Сообщений - ' + c + ']';
            }
        }
        return res;
    }

    editCount = (point: Point) => {
        this.setState({showEditNumForm: point, modalZIndex: getModalZIndex(), editNum: this.state.abonentsDaySendMaxCount[point.id] ?? Const.ABONENTS_DAY_SEND_MAX_COUNT_DEFAULT})
    }

    editCountForGroup = (group: Group) => {
        this.setState({showEditNumForm: group, modalZIndex: getModalZIndex(), editNum: Const.ABONENTS_DAY_SEND_MAX_COUNT_DEFAULT})
    }

    render() {
        const pointCommands: Command[] = [
            {key: 'editCount', caption: "Изменить максимальное количество отсылаемых сообщений в день", action: this.editCount},
        ];
        const groupCommands: Command[] = [
            {key: 'editCount', caption: "Изменить максимальное количество отсылаемых сообщений в день для ТУ в группе", action: this.editCountForGroup},
        ];
        const commands: any = {};
        commands[NodeTypesEnum.Point] = pointCommands;
        commands[NodeTypesEnum.Group] = groupCommands;

        return (
            <>
                <Modal
                    style={this.props.smallWindow ? { top: '3px' } : {}}
                    title={"Редактирование количества сообщений в день по ТУ"}
                    wrapClassName={Const.MODAL_WRAP_CLASSNAME}
                    zIndex={this.props.zIndex}
                    open={this.props.visible}
                    onOk={() => this.props.onOk(this.state.abonentsDaySendMaxCount)}
                    onCancel={this.props.onHide}
                    okText= "Ok"
                    cancelText="Отмена"
                    mask={false}
                >
                    <div className={styles.FormWrapper}>
                    <PointsControlTree
                        draggable={false}
                        selectedPoint={undefined}
                        needReloadGroupIds={[]}
                        height={500}
                        groups={this.props.groups}
                        groupPoints={this.props.groupPoints}
                        getPoints={this.props.getPoints}
                        setIsLoading={this.props.setIsLoading}
                        additionalTitle = {this.additionalNodeTitle}
                        commands={commands}
                        filter={(ob) => {
                            let res = true;
                            if ((ob as Point).number != undefined) {
                                if (!Object.keys(this.state.abonentsDaySendMaxCount).includes((ob as Point).id)) {
                                    res = false;
                                }
                            }
                            return res;
                        }}
                    />
                    </div>
                </Modal>
                <EditNumberForm
                    allowNull={false}
                    number={this.state.editNum}
                    visibleObj={this.state.showEditNumForm}
                    zIndex={this.state.modalZIndex}
                    onHide={() => this.setState({showEditNumForm: null})}
                    title={'Количество сообщений'}
                    height={80}
                    width={259}
                    onOk={(val: number | null, obj: any) => {
                            if ((obj as Point).number != undefined) {
                                this.setState({showEditNumForm: null, abonentsDaySendMaxCount: {...this.state.abonentsDaySendMaxCount, [(obj as Point).id]: val ?? Const.ABONENTS_DAY_SEND_MAX_COUNT_DEFAULT}})
                            } else if ((obj as Group).childGroups != undefined) {
                                this.props.setIsLoading(true, 500);
                                getAllSelectedPoints(this.props.groups, [(obj as Group).id], [], this.props.getPoints)
                                .then(allPoints => {
                                    this.props.setIsLoading(false);
                                    const points = allPoints.reduce((obj, cur) => ({...obj, [cur.id]: val ?? Const.ABONENTS_DAY_SEND_MAX_COUNT_DEFAULT}), {});
                                    this.setState({showEditNumForm: null, abonentsDaySendMaxCount: {...this.state.abonentsDaySendMaxCount, ...points}})
                                });

                                
                            }
                        }
                    }
                />
            </>
        );
    }
}

export default ContactPointDaySendMaxCount;