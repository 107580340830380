import * as React from 'react';
import ResizableTable from '../Helper/ResizableTable';
import { Button, Input, Space, Tooltip, message } from 'antd';
import moment from 'moment';
import { sendRequestToBackend } from '../../utils/AuthUtils';
import { makeCSVfromTable, saveFile, toDate, unicodeToWin1251 } from '../../utils/Common';
import { SMessageSendItemDTO } from '../../store/AdminStore';
import styles from '../../resources/Page.module.less';
import { FileZipOutlined, SyncOutlined } from '@ant-design/icons';
import CollapsedButton from '../Helper/CollapsedButton';
import RangePickerLimit from '../Helper/RangePickerLimit';

type Props = {
    setIsLoading: any,
    windowHeight: number,
}

interface State {
    beginDate: Date,
    endDate: Date,
    searchText: string,
    sentMessages: SMessageSendItemDTO[] | undefined,
}

export default class SentMessages extends React.PureComponent<Props, State> {

    constructor(props: Props) {
        super(props);

        this.state = {
            beginDate: moment(toDate(new Date())).add(-7, 'day').toDate(),
            endDate: toDate(new Date()),
            searchText: '',
            sentMessages: undefined,
        }
    }
    componentDidMount() {
        this.refresh();
    }

    componentDidUpdate(prevProps: Props, prevState: State) {

    }

    refresh = () => {
        this.request();
    }

    request = () => {
        sendRequestToBackend(
            {start: toDate(this.state.beginDate), end: moment(toDate(this.state.endDate)).add(23, 'hours').add(59, 'minutes').toDate()},
            'admin/messagesentitems',
            (response: any) => {
                this.setState({sentMessages: response})
                this.setDataSource(response, this.state.searchText);
            },
            null,
            (error: any) => {                                
                console.log(error);
                message.error(error);
            }
        );
    }

    setDataSource = (items: SMessageSendItemDTO[] | undefined, searchText: string) => {
        this.dataSource = items
        ?.filter(x => (
            x.abonentNumber.toLowerCase().includes(searchText.toLowerCase())
            || x.userName.toLowerCase().includes(searchText.toLowerCase())
            || x.contactClientID.toLowerCase().includes(searchText.toLowerCase())
            || x.messageText.toLowerCase().includes(searchText.toLowerCase())
        ))
        .map((x, index) => ({
            key: x.id,
            num: index + 1,
            user: x.userName,
            pointNumber: x.abonentNumber,
            contact: String(x.contactClientID),
            sentTime: x.sentTime,
            requestTime: x.requestTime,
            eventTime: x.eventTime,
            message: x.messageText,
        }))

        this.forceUpdate();
    }

    dataSource: any[] | undefined = undefined;

    private columns: any[] =
        [
            { title: '', dataIndex: 'num', width: 30, ellipsis: true, resizable: true, render: (val: any, record: any, index: any) => index + 1},
            { title: 'Пользователь', dataIndex: 'user', width: 140, ellipsis: true, resizable: true, sorter: (a: any, b: any) => this.sorter(a.user, b.user),},
            { title: 'Точка учета', dataIndex: 'pointNumber', width: 140, ellipsis: true, resizable: true, sorter: (a: any, b: any) => this.sorter(a.pointNumber, b.pointNumber),},
            { title: 'Контакт', dataIndex: 'contact', width: 140, ellipsis: true, resizable: true, sorter: (a: any, b: any) => this.sorter(a.contact, b.contact),},
            { title: 'Время события в приборе', dataIndex: 'eventTime', width: 140, ellipsis: true, resizable: true, sorter: (a: any, b: any) => this.sorter(a.eventTime, b.eventTime), render: (val: any, record: any, index: any) => moment(val).format('DD.MM.YYYY HH:mm'),},
            { title: 'Назначенное время отправки', dataIndex: 'requestTime', width: 140, ellipsis: true, resizable: true, sorter: (a: any, b: any) => this.sorter(a.requestTime, b.requestTime), render: (val: any, record: any, index: any) => moment(val).format('DD.MM.YYYY HH:mm'),},
            { 
                title: 'Время отправки', dataIndex: 'sentTime', width: 140, ellipsis: true, resizable: true, sorter: (a: any, b: any) => this.sorter(a.sentTime, b.sentTime), 
                render: (val: any, record: any, index: any) => {
                    if (val == null) {
                        return <span style={{color: 'red'}}>Не отправлено</span>
                    } else {
                        return moment(val).format('DD.MM.YYYY HH:mm')
                    }
                }
            },
            { title: 'Сообщение', dataIndex: 'message', width: 300, ellipsis: true, resizable: true, sorter: (a: any, b: any) => this.sorter(a.message, b.message),},
        ]

    sorter = (a: any, b: any) => (isNaN(a) && isNaN(b) ? (a || '').localeCompare(b || '') : a - b);

    //===============================================
    render() {
        const clientHeight = this.props.windowHeight - 230;
        return ( 
            <div style={{ height: 'auto', padding: 2, backgroundColor: '#cfcfcf' }}>
                <>
                <Space direction="vertical" style={{marginBottom: '5px', marginTop: '5px'}}>
                <div className={styles.flexcontainer}>
                    <CollapsedButton tooltipPlacement='top' type="primary" icon={<SyncOutlined/>} onClick={() => {this.refresh()}}>Обновить</CollapsedButton>

                    <div key='fi2' className={styles.flexsmallitem}>
                        <RangePickerLimit
                            value={[this.state?.beginDate, this.state?.endDate]}
                            onChange={(dates) => this.setState({beginDate: dates?.[0] ?? new Date(), endDate: dates?.[1] ?? new Date()})}
                            allowClear={false}
                            style={{width: '250px'}}
                        />
                    </div>

                    <div key='fi6' className={styles.flexsmallitem}>
                        <Input value={this.state.searchText} placeholder="Поиск" 
                            onChange={(ev: any) => {
                                this.setState({searchText: ev.target.value});
                                this.setDataSource(this.state.sentMessages, ev.target.value); 
                            }}
                            style={{width: '200px'}}
                        />
                    </div>

                    <div key='fi7' style={{marginLeft: '100px'}}>
                    <Button shape='round' type="primary" icon={<FileZipOutlined />} onClick={() => {
                        let data = makeCSVfromTable(this.dataSource ?? [], this.columns.filter(x => x.dataIndex != 'num'));
                        saveFile("Отчет.csv", unicodeToWin1251(data));
                    }}>
                    Экспорт данных
                    </Button>
                </div>
                </div>
                </Space>

                <ResizableTable
                    scroll={{y: clientHeight-130}} size='small'
                    columns={this.columns}
                    dataSource={this.dataSource}
                    bordered
                    pagination={{ showSizeChanger: true, pageSizeOptions: ["7", "14", "100", "500", "1000"], defaultPageSize: 14, locale: { items_per_page: " / страницу" }}}
                />
                </>
            </div>
        );
    }
}


