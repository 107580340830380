import * as React from 'react';
import ReactDOM from 'react-dom'; 
import { DatePicker } from '@skbkontur/react-ui';

export const createCalendar = (el: any, props: any) => {
    ReactDOM.render(React.createElement(Calendar, props), el);
}

const Calendar = (props: any) => {
    const [value, setValue] = React.useState(props.value);
    const reset = (date: string | null) => {
        setValue(date);
        props.onValueChange(date);
    }
    return (
        <DatePicker
            value={value}
            onValueChange={reset}
        />
    );
};

